<template>
  <div class="empty-layout">
    <MLoader />
    <div id="viewport">
      <slot />
      <OModal />
      <ONotification />
      <MCookieNotification />
      <MOfflineBadge />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import OModal from '../components/organisms/o-modal';
import MLoader from '../components/molecules/m-loader';
import ONotification from '../components/organisms/o-notification';
import MCookieNotification from '../components/molecules/m-cookie-notification';
import MOfflineBadge from '../components/molecules/m-offline-badge';
import ShippingLocationMixin from '$modules/shipping/mixins/shipping-location-mixin';
import { isServer } from '@vue-storefront/core/helpers';
import config from 'config';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { metaCore } from 'theme/meta/base';
import Prefetch from 'theme/mixins/Prefetch';
import { isSkipSSR } from 'theme/store/checkout/helpers';

export default {
  components: {
    MLoader,
    ONotification,
    MCookieNotification,
    MOfflineBadge,
    OModal
  },
  data () {
    return {
      quicklink: null
    };
  },
  computed: {
    quicklinkEnabled () {
      return typeof config.quicklink !== 'undefined' && config.quicklink.enabled
    }
  },
  mixins: [ShippingLocationMixin, Prefetch],
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      this.$Progress.increase(40);
      next();
    });
    this.$router.afterEach(() => {
      if (!isServer && this.quicklinkEnabled) {
        this.quicklink.listen();
      }
      this.$Progress.finish();
    });
  },
  mounted () {
    if (!isServer && this.quicklinkEnabled) {
      this.quicklink = require('quicklink');
      this.quicklink.listen();
    }
    this.$store.dispatch('ui/checkWebpSupport');
  },
  serverPrefetch () {
    if (isSkipSSR()) return

    return this.fetchMenuData();
  },
  metaInfo: mergeMeta(metaCore)
};
</script>

<style lang="scss" scoped>
.default-layout {
  min-width: var(--min-width);
}
</style>
