<template>
  <div
    class="default-layout"
    :class="routeBasedClass"
  >
    <MLoader />
    <div id="viewport">
      <MBannerPreHeader v-if="preHeaderBanners.length > 0" />
      <OHeader />
      <OHeaderBottom />
      <SfOverlay
        :class="{'overlay-search': isSearchOverlay }"
        :visible="isSearchOverlay"
        :transition="'none'"
        @click="hideOverlay"
      />
      <OMicrocart />
      <slot />
      <OFooter />
      <OModal />
      <ONotification />
      <MCookieNotification />
      <MOfflineBadge />
      <OBottomNavigation />
      <no-ssr>
        <MAddBinanceCard />
      </no-ssr>
    </div>
    <no-ssr>
      <vue-progress-bar />
    </no-ssr>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance
} from 'vue'
import { isServer } from '@vue-storefront/core/helpers'
import OMicrocart from '../components/organisms/o-microcart';
import OModal from '../components/organisms/o-modal';
import OBottomNavigation from '../components/organisms/o-bottom-navigation';
import MLoader from '../components/molecules/m-loader';
import ONotification from '../components/organisms/o-notification';
import MCookieNotification from '../components/molecules/m-cookie-notification';
import MOfflineBadge from '../components/molecules/m-offline-badge';
import config from 'config';
import { ModalList } from '../store/ui/modals'
import OFooter from '../components/organisms/o-footer';
import OHeader from '../components/organisms/o-header';
import OHeaderBottom from '../components/organisms/o-header-bottom';
import MBannerPreHeader from 'theme/components/molecules/m-banner-pre-header';
import { metaCore } from 'theme/meta/base';
import DeviceType from 'theme/mixins/DeviceType';
import ShippingLocationMixin from '$modules/shipping/mixins/shipping-location-mixin';
import Prefetch from 'theme/mixins/Prefetch';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import NoSSR from 'vue-no-ssr';
import MAddBinanceCard from 'theme/components/molecules/m-add-binance-card'
import { SfOverlay } from '@storefront-ui/vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    OHeader,
    OHeaderBottom,
    OFooter,
    OMicrocart,
    MLoader,
    ONotification,
    MCookieNotification,
    MOfflineBadge,
    OBottomNavigation,
    OModal,
    MBannerPreHeader,
    'no-ssr': NoSSR,
    MAddBinanceCard,
    SfOverlay
  },
  mixins: [ShippingLocationMixin, DeviceType, Prefetch],
  setup () {
    const { proxy: vm } = getCurrentInstance()
    const quicklink = ref(null)

    // Computed properties
    const isSearchPanelVisible = computed(() => vm.$store.state.ui.searchpanel)
    const isSearchOverlay = computed(() => isSearchPanelVisible.value)
    const quicklinkEnabled = computed(() =>
      typeof config.quicklink !== 'undefined' && config.quicklink.enabled
    )

    const routeBasedClass = computed(() => {
      const currentPageComponent = vm.$route.matched[0].components.default.name
      return currentPageComponent ? `${currentPageComponent.toLowerCase()}-layout` : ''
    })
    const preHeaderBanners = computed(() => vm.$store.getters['promoted/getPreHeaderBanners'])

    // Methods
    const hideOverlay = () => {
      vm.$store.commit('ui/setSearchpanel', false)
      vm.$store.commit('ui/closeCatalog')
      vm.$store.commit('ui/closeMenu')
    }

    const onCartsConfirmation = (payload) => {
      vm.$store.dispatch('ui/openModal', {
        name: ModalList.CartsConfirmation,
        payload
      })
    }

    // Lifecycle hooks
    onBeforeMount(() => {
      vm.$router.beforeEach((to, from, next) => {
        vm.$Progress.start()
        vm.$Progress.increase(40)
        next()
      })

      vm.$router.afterEach(() => {
        if (!isServer && quicklinkEnabled.value) {
          quicklink.value.listen()
        }
        vm.$Progress.finish()
      })

      vm.$bus.$on('cart-authorize-diff-items', onCartsConfirmation)
    })

    onMounted(() => {
      if (!isServer && quicklinkEnabled.value) {
        quicklink.value = require('quicklink')
        quicklink.value.listen()
      }
      vm.$store.dispatch('ui/checkWebpSupport')
      vm.$store.dispatch('user/trackingCheckQuery')
    })

    onBeforeUnmount(() => {
      vm.$bus.$off('cart-authorize-diff-items', onCartsConfirmation)
    })

    return {
      quicklink,
      isSearchOverlay,
      quicklinkEnabled,
      hideOverlay,
      onCartsConfirmation,
      routeBasedClass,
      preHeaderBanners
    }
  },
  metaInfo: mergeMeta(metaCore)
})
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.default-layout {
  min-width: var(--min-width);

  @include for-desktop {
    padding-top: 0;
  }
}
</style>
